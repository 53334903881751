import { useState, useEffect } from 'react'

interface AvaConfig {
  token: string
  baseUrl: string
  // Add any other properties that might be in the config
}

function useAvaConfig(): AvaConfig | null {
  const [config, setConfig] = useState<AvaConfig | null>(null)

  useEffect(() => {
    const checkConfig = () => {
      if (typeof window !== 'undefined' && window.avaConfig) {
        setConfig(window.avaConfig)
      } else {
        // If config is not available, check again after a short delay
        setTimeout(checkConfig, 100)
      }
    }

    checkConfig()

    // Cleanup function
    return () => {
      // If you need to clean up any resources, do it here
    }
  }, [])

  useEffect(() => {
    if (config) {
      console.log('Config loaded:', config)
      // You can perform any operations that depend on config here
    }
  }, [config])

  return config
}

export default useAvaConfig

// Add this to your global.d.ts file or at the top of this file
declare global {
  interface Window {
    avaConfig?: AvaConfig
  }
}

import React, { useMemo } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useAvaConfig from '../hooks/useAvaConfig'
import AskTedIcon from '../icons/ask-ted'
import BubbleIcon from '../icons/bubble'
import UseCase2Icon from '../icons/use-case-2'
import { stripTrailingSlash } from '../helper/url'
import urls from '../data/url.json'
import { LANDING_PAGE } from '../config'

const TedMobileV1 = () => {
  const config = useAvaConfig()
  const path = window.location.pathname

  const currentData = useMemo(() => {
    const pathItem = stripTrailingSlash(path)
    const data = (urls as any)[pathItem]
    return data
  }, [path])

  const iframeRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [expand, setExpand] = useState(false)
  const [isOpenIframe, setIsOpenIframe] = useState(false)
  const [query, setQuery] = useState('')
  const [isFirstVisit, setIsFirstVisit] = useState(false)
  const [closeAfterFirstVisit, setCloseAfterFirstVisit] = useState(false)
  const [tedNotfound, setTedNotfound] = useState(false)
  const [idleTime, setIdleTime] = useState(5000)

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data === 'close-popup') {
        setIsOpen(false)
        setIsOpenIframe(false)
        setTedNotfound(false)
      } else if (event.data.includes?.('open-ted')) {
        const eventParts = event.data.split('|')
        if (eventParts.length > 1) {
          setQuery(eventParts[1])
        } else {
          setQuery('')
        }

        if (path.includes('search')) {
          setTedNotfound(true)
          setExpand(true)
        } else {
          openIframe()
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [config])

  const containerVariants = {
    collapsed: {
      width: 60,
      justifyContent: 'center',
      paddingRight: '0px',
      paddingLeft: '0px',
    },
    expanded: {
      width: 'auto',
      justifyContent: 'flex-end',
      paddingRight: '50px',
      paddingLeft: '5px',
    },
    exit: {
      width: 60,
      transition: {
        width: { type: 'spring', stiffness: 300, damping: 30 },
        padding: { duration: 0.5, ease: 'easeInOut' },
      },
    },
  }

  const textContainerVariants = {
    hidden: { opacity: 0, width: 0 },
    visible: {
      opacity: 1,
      width: 'auto',
      transition: {
        width: { type: 'spring', stiffness: 300, damping: 30 },
        opacity: { duration: 0.2, delay: 0.5 },
      },
    },
    exit: {
      opacity: 0,
      width: 0,
      transition: {
        opacity: { duration: 0.4, ease: 'easeInOut' }, // Slower fade out
        width: { duration: 0.5, ease: 'easeInOut', delay: 0.1 }, // Smoother width collapse
      },
    },
  }

  useEffect(() => {
    if (path !== LANDING_PAGE) return
    // Check if it's the first visit in this session
    const checkFirstVisit = () => {
      console.log('check user first visit')
      const hasVisitedThisSession = sessionStorage.getItem('hasVisitedMobile')
      console.log(hasVisitedThisSession)
      if (!hasVisitedThisSession) {
        sessionStorage.setItem('hasVisitedMobile', 'true')
        console.log('user first visit')
        // setTimeout(() => {
          setIsFirstVisit(true)
          setExpand(true)
        // }, 1000)
      } else {
        setIsFirstVisit(false)
      }
    }

    checkFirstVisit()
  }, [])

  const greetingMessage = isFirstVisit
    ? "Hi, I'm Ted! Looks like you're new here. Need any assistance?"
    : tedNotfound
    ? "Haven't discovered your desired product yet? I’m here to help"
    : "Have you found what you're looking for?"

  useEffect(() => {
    if (path !== LANDING_PAGE) return
    if (expand) return

    console.log('idle time ', idleTime, isFirstVisit)
    const timer = setTimeout(() => {
      let newIdleTime = idleTime * 3 > 45000 ? 45000 : idleTime * 3
      setIdleTime(newIdleTime)
      setExpand(true)
    }, idleTime)

    return () => {
      clearTimeout(timer)
    }
  }, [path, expand])

  const getIframeStyles = () => {
    const width = window.innerWidth

    let maxHeight = '100%'
    let maxWidth = '100%'
    let widthFrame = '98%'
    let heightFrame = '-webkit-fill-available'
    let right = '0rem'
    let bottom = '2rem'
    let left = ''
    let borderRadius = '0.75rem'

    if (width >= 1660) {
      maxHeight = 'calc(100vh - 12rem)'
      maxWidth = '380px'
    } else if (width >= 1327) {
      maxHeight = 'calc(100vh - 9rem)'
      maxWidth = '380px'
    } else if (width >= 1106) {
      maxHeight = 'calc(100vh - 1rem)'
      maxWidth = '380px'
    } else if (width >= 948) {
      maxHeight = 'calc(100vh - 3rem)'
      maxWidth = '380px'
    } else if (width >= 834) {
      maxHeight = 'calc(100vh - 3rem)'
      maxWidth = '350px'
    } else if (width >= 360) {
      maxHeight = '100dvh'
      maxWidth = '100%'
    }

    if (width <= 320) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      left = '0rem'
      borderRadius = '0rem'
      bottom = '0'
    } else if (width <= 375) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      left = '0rem'
      borderRadius = '0rem'
      bottom = '0'
    } else if (width <= 768) {
      widthFrame = '100vw'
      heightFrame = '100vh'
      right = '0rem'
      bottom = '0rem'
      borderRadius = '0rem'
    } else if (width < 1024) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      bottom = '0rem'
      borderRadius = '0rem'
    } else if (width >= 1024) {
      widthFrame = '30rem'
      heightFrame = '50rem'
      right = '1rem'
      bottom = '5rem'
    } else if (width >= 1440) {
      widthFrame = '30rem'
      heightFrame = '50rem'
      right = '1rem'
      bottom = '5rem'
    }

    return {
      border: 'none',
      position: 'fixed',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow:
        'rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px',
      bottom,
      right,
      left,
      width: widthFrame,
      maxWidth,
      height: heightFrame,
      maxHeight,
      borderRadius,
      display: 'flex',
      zIndex: 2147483647,
      overflow: 'hidden',
      backgroundColor: '#F3F4F6',
    }
  }

  const containerStyle = {
    position: 'relative',
  }

  const closeIconStyle = {
    position: 'absolute',
    top: '-28px',
  }

  const mainContainerStyle = {
    position: 'relative',
    height: '60px',
    borderRadius: '9999px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'linear-gradient(160deg, #1ea5e3 0%, #c1003a 100%)',
    maxWidth: '300px',
    marginLeft: '5px',
  }

  const textContainerStyle = {
    color: 'white',
    paddingLeft: '18px',
    paddingRight: '10px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '300px',
  }

  const textStyle = {
    fontSize: '13px',
  }

  const iconContainerStyle = {
    backgroundColor: '#001A41',
    height: '50px',
    width: '50px',
    borderRadius: '9999px',
    flexShrink: 0,
    position: 'absolute',
    right: '4px',
    bottom: '4px',
  }

  const iconStyle = {
    borderRadius: '9999px',
    marginTop: '-14px',
    marginLeft: '-5px',
    width: '60px',
    height: '60px',
  }

  if (!config) return <></>

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
  }

  const Backdrop = styled(motion.div)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 26, 65, 60%);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    z-index: 2147483646;
  `

  const MenuContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 16px;
    bottom: 96px;
    gap: 16px;
  `

  const menuVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        when: 'beforeChildren',
        staggerChildren: 0.1,
      },
    },
  }

  const MenuButton = styled(motion.button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    width: 260px;
    height: 46px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    border-radius: 9999px;
    position: relative;
    overflow: hidden;
    border: none;
  `

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', stiffness: 300, damping: 24 },
    },
  }

  const ButtonBackground = styled.div`
    position: absolute;
    inset: 0;
    z-index: 0;
    background: linear-gradient(160deg, #1ea5e3 0%, #c1003a 100%);
  `

  const ButtonText = styled.span`
    position: relative;
    z-index: 10;
    font-size: 14px;
  `

  const IconWrapper = styled.div`
    position: relative;
    z-index: 10;
  `

  const showGreet = expand && !isOpenIframe

  const openIframe = () => {
    setIsOpenIframe(true)
    setExpand(false)
    setIsOpen(false)
  }

  return (
    <div style={containerStyle}>
      {showGreet && (
        <AnimatePresence>
          <div
            style={closeIconStyle}
            onClick={() => {
              setExpand(false)
              setIsFirstVisit(false)
            }}
          >
            <img src={`${config.baseUrl}/revamp/x.svg`} alt="Close" />
          </div>
        </AnimatePresence>
      )}
      <motion.div
        variants={containerVariants}
        initial="collapsed"
        animate={showGreet ? 'expanded' : 'collapsed'}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 30,
          width: { type: 'spring', stiffness: 300, damping: 30 },
          padding: { duration: 0.3, ease: 'easeInOut' },
          justifyContent: { duration: 0.3 },
        }}
        style={mainContainerStyle}
      >
        <AnimatePresence>
          {showGreet && (
            <motion.div
              style={textContainerStyle}
              variants={textContainerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={openIframe}
            >
              <motion.h1
                style={textStyle}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{
                  duration: 0.2,
                  delay: expand ? 0.5 : 0,
                }}
              >
                {greetingMessage}
              </motion.h1>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.div
          style={iconContainerStyle}
          whileHover={showGreet ? { scale: 1.05 } : {}}
          whileTap={showGreet ? { scale: 0.95 } : {}}
        >
          <img
            src={`${config.baseUrl}/revamp/ted.svg`}
            style={iconStyle}
            alt="Ted"
            onClick={openIframe}
          />
        </motion.div>
      </motion.div>

      <AnimatePresence>
        {isOpen && (
          <Backdrop
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={() => setIsOpen(false)}
          >
            <MenuContainer
              variants={menuVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {['Summarize', 'See Use Case', 'Ask Ted'].map((text, index) => (
                <MenuButton
                  key={text}
                  variants={itemVariants}
                  whileHover="hover"
                  whileTap="tap"
                  onClick={() => {
                    switch (index) {
                      case 0:
                        return window.ted.openTed(currentData.questions[0])
                      case 1:
                        return window.open(currentData.usecase)
                      case 2:
                        return window.ted.openTed()
                    }
                    setIsOpenIframe(true)
                  }}
                >
                  <ButtonBackground />
                  <ButtonText>{text}</ButtonText>
                  <IconWrapper>
                    {index === 0 && <BubbleIcon />}
                    {index === 1 && <UseCase2Icon />}
                    {index === 2 && <AskTedIcon />}
                  </IconWrapper>
                </MenuButton>
              ))}
            </MenuContainer>
          </Backdrop>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isOpenIframe && (
          <motion.iframe
            ref={iframeRef}
            allow="fullscreen; microphone"
            title="dify chatbot bubble window"
            id="ted-iframe"
            src={`${config.baseUrl}/ted?token=${config.token}&width=${window.innerWidth}&query=${query}`}
            style={getIframeStyles()}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default TedMobileV1

import { createGlobalStyle } from 'styled-components'

const url = window.avaConfig?.baseUrl || 'http://google.com'
const batikSans = `${url}/telkomsel-font/batik-sans-bold.otf`
const batikSansBold = `${url}/telkomsel-font/batik-sans-regular.otf`

const GlobalFont = createGlobalStyle`
  @font-face {
    font-family: 'BatikSans';
    font-weight: 400;
    src: url(${batikSans});
  }

  @font-face {
    font-family: 'BatikSans';
    font-weight: 700;
    src: url(${batikSansBold});
  }

  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

  body {
    font-family: "Poppins", "BatikSans", sans-serif;
  }
`

export default GlobalFont

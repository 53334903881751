{
  "/": {
    "status": true,
    "usecase": null,
    "questions": [
      "apa itu telkomsel enterprise",
      "informasi tentang solusi konektivitas",
      "informasi tentang solusi IoT"
    ]
  },
  "/enterprise/product-list-cyber/sma": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa itu Telco Verify?",
      "Bagaimana cara kerja Telco Verify?",
      "Apakah Telco Verify butuh OTP?",
      "Apa keunggulan Telco Verify?",
      "Berapa harga layanan Telco verify?",
      "Bisakah Telco Verify digunakan di WiFi?",
      "Apakah Telco Verify mendukung banyak operator?",
      "Bagaimana cara mengintegrasikan Telco Verify?",
      "Apa saja kasus penggunaan Telco Verify?",
      "Apakah Telco Verify menyediakan SDK?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/private-network": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/private-network#studi-section",
    "questions": [
      "Apa keuntungan private network?",
      "Siapa target pasar private network?",
      "Berapa lama implementasi private network?",
      "Apa saja masalah yang diatasi private network?",
      "Bagaimana cara meningkatkan efisiensi melalui private network?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/femtocell": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/femtocell#studi-section",
    "questions": [
      "Apa manfaat Femtocell bagi pelanggan?",
      "Jenis apa saja dari perangkat Femtocell?",
      "Siapa target pasar Femtocell?",
      "Berapa kapasitas area yang dapat dicakup femtocell?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/GPUaaS": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/GPUaaS#studi-section",
    "questions": [
      "Apa fungsi utama GPUaaS?",
      "Siapa yang dapat menggunakan GPUaaS?",
      "Bagaimana cara akses GPUaaS?",
      "Apa manfaat GPUaaS bagi pengembang AI?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/managed-service-router": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/managed-service-router#studi-section",
    "questions": [
      "Apa yang ditawarkan layanan Managed Service Router?",
      "Berapa SLA layanan Managed Service Router?",
      "Siapa yang membutuhkan layanan Managed Service Router"
    ]
  },
  "/enterprise/product-list-advanced-network-services/managed-service-sdwan": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/managed-service-sdwan#studi-section",
    "questions": [
      "Apa yang diatasi oleh solusi SD-WAN?",
      "Berapa SLA yang ditawarkan SD-WAN?",
      "Siapa target pasar untuk SD-WAN?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/iaas": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/iaas#studi-section",
    "questions": [
      "Apa yang dimaksud dengan IaaS disini?",
      "Siapa yang membutuhkan layanan IaaS?",
      "Apa yang dibutuhkan untuk implementasi IaaS?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/direct-peering": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/direct-peering#studi-section",
    "questions": [
      "Apa manfaat dari Direct Peering?",
      "Siapa yang dapat menggunakan produk Direct Peering",
      "Apa kendala yang diatasi oleh produk Direct Peering"
    ]
  },
  "/enterprise/product-list-advanced-network-services/network-prioritization": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/network-prioritization#studi-section",
    "questions": [
      "Apa manfaat Network Prioritization?",
      "Untuk industri apa produk Network Prioritization?",
      "Apa yang diatasi oleh Network Prioritization?"
    ]
  },
  "/enterprise/product-list-advanced-network-services/managed-service-repeater": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/managed-service-repeater#studi-section",
    "questions": [
      "Siapa yang membutuhkan layanan Managed Service Repeater",
      "Berapa lama masa langganan Managed Service Repeater",
      "Apa manfaat dari layanan Managed Service Repeater"
    ]
  },
  "/enterprise/product-list-advanced-network-services/5g-and-mec": {
    "status": true,
    "usecase": "https://telkomsel.com/enterprise/product-list-advanced-network-services/5g-and-mec#studi-section",
    "questions": [
      "Apa keuntungan menggunakan 5G MEC?",
      "Untuk apa 5G MEC dirancang?",
      "Apa yang membuat produk 5G MEC unik?"
    ]
  },
  "/enterprise/product-list-iot/iot-managed-edc": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-managed-edc#studi-section",
    "questions": [
      "Apa solusi Managed EDC?",
      "Siapa target pasar Managed EDC?",
      "Bagaimana cara integrasi produk EDC?",
      "Apa saja manfaat Managed EDC?",
      "Apa saja fitur yang ditawarkan EDC?",
      "Apa masalah yang diatasi oleh EDC?",
      "Bagaimana proses penagihan Managed EDC?",
      "Apa model bisnis yang ditawarkan oleh Managed EDC?",
      "Apa itu layanan managed service EDC?",
      "Siapa yang bisa menggunakan solusi EDC?"
    ]
  },
  "/enterprise/product-list-business-application-and-solution/field-force-management": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-business-application-and-solution/field-force-management#studi-section",
    "questions": [
      "Apa keuntungan Field Force Management?",
      "Bagaimana cara kerja Field Force Management?",
      "Siapa target pasar Field Force Management",
      "Apa manfaat digitalisasi Field Force Management",
      "Apa saja fitur dari Field Force Management?",
      "Apa masalah yang diatasi Field Force Management"
    ]
  },
  "/enterprise/product-list-business-application-and-solution/human-resource-apps": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-business-application-and-solution/human-resource-apps#studi-section",
    "questions": [
      "Apa itu Human Resource App?",
      "Fitur apa saja yang tersedia dari Human Resource App",
      "Apa keuntungan menggunakan Human Resource App"
    ]
  },
  "/enterprise/product-list-business-application-and-solution/smart-tax": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-business-application-and-solution/smart-tax#studi-section",
    "questions": [
      "Apa fungsi Smart Tax?",
      "Bagaimana cara memantau pajak Smart Tax",
      "Apa manfaat Smart Tax bagi pendapatan daerah?"
    ]
  },
  "/enterprise/product-list-business-application-and-solution/pembukuan-digital": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-business-application-and-solution/pembukuan-digital#studi-section",
    "questions": [
      "Bagaimana Pembukuan Digital bekerja?",
      "Apa saja laporan yang dihasilkan Pembukuan Digital ?",
      "Siapa yang dapat menggunakan Pembukuan Digital ?",
      "Apa keuntungan dari laporan otomatis Pembukuan Digital ?"
    ]
  },
  "/enterprise/product-list-business-application-and-solution/koperasi-digital": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-business-application-and-solution/koperasi-digital#studi-section",
    "questions": [
      "Apa itu Koperasi Digital?",
      "Fitur apa yang didukungKoperasi Digital?",
      "Bagaimana sistem manajemen produk Koperasi Digital?",
      "Apa biaya langganan Koperasi Digital terjangkau?"
    ]
  },
  "/enterprise/product-list-digital-advertising/tadex": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-digital-advertising/tadex#Publisher-tab",
    "questions": [
      "Apa itu TADEX?",
      "Bagaimana cara kerja TADEX?",
      "Apa keuntungan menggunakan TADEX?",
      "Siapa target pasar TADEX?",
      "Format iklan apa yang tersedia di TADEX?",
      "Berapa ukuran banner yang direkomendasikan TADEX?",
      "Apa itu TADEX Interactive Banner?",
      "Dapatkah saya menggunakan TADEX tanpa DSP?",
      "Berapa lama proses menjadi advertiser di TADEX?",
      "Apa saja pelanggan TADEX sebelumnya?"
    ]
  },
  "/enterprise/product-list-cie/msight": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa itu Msight API Recycle Number?",
      "Bagaimana cara kerja Msight API Recycle Number?",
      "Siapa target pasar Msight API Recycle Number?",
      "Apa manfaat Msight API Recycle Number bagi lembaga keuangan?",
      "Apa keuntungan bagi pengguna Msight API Recycle Number ini?",
      "Apa yang diperiksa oleh Msight API Recycle Number Location Verification?",
      "Bagaimana cara kerja API NIK Matching?",
      "Apa nilai tambah API Telco Score?",
      "Siapa yang dapat menggunakan  Msight API ?",
      "Apa yang diukur oleh Telco Score?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/cpaas": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/cpaas#studi-section",
    "questions": [
      "Apa itu Telkomsel ngage?",
      "Bagaimana cara mengintegrasikan nGage?",
      "Apa manfaat ngage menggunakan Number Masking?",
      "Untuk siapa ngage  Voicebot ini ditujukan?",
      "Sebutkan cara kerja Robocall ngage !",
      "Apa yang dimaksud dengan Video API ngage ?",
      "Bagaimana cara kerja solusi Omnichannel ?",
      "Apa keuntungan menggunakan  nGage  SMS API?",
      "Siapa target pasar nGage?",
      "Bagaimana nGage menghemat biaya?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/smart-voice-communication": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/smart-voice-communication#studi-section",
    "questions": [
      "Apa itu Smart Voice Communication?",
      "Bagaimana cara kerja Smart Voice Communication?",
      "Apa manfaat tarif flat Smart Voice Communication untuk pelanggan?",
      "Siapa target pasar Smart Voice Communication?",
      "Apa yang diatasi oleh layanan Smart Voice Communication?",
      "Apa fitur tambahan Smart Voice Communication untuk pelanggan?",
      "Berapa banyak pelanggan Smart Voice Communication yang sudah ada?",
      "Apa indikasi harga Smart Voice Communication?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/touch-to-talk": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/touch-to-talk#studi-section",
    "questions": [
      "Apa itu Touch to Talk?",
      "Bagaimana cara penggunaan Touch to Talk?",
      "Apa saja pasar target dari layanan Touch to Talk?",
      "Apa manfaat dari komunikasi digital Touch to Talk?",
      "Apa yang diatasi oleh produk Touch to Talk?",
      "Berapa biaya yang diperlukan untuk Touch to Talk?",
      "Apa fitur utama dari produk Touch to Talk?",
      "Bagaimana layanan Touch to Talk memberikan visibilitas lokasi?",
      "Apa skema bisnis yang ditawarkan Touch to Talk?"
    ]
  },
  "/enterprise/product-list-iot/iot-control-center": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-control-center",
    "questions": [
      "Apa manfaat dari IoT Control Center?",
      "Bagaimana cara kerja produk IoT Control Center?",
      "Apa fitur utama IoT Control Center?",
      "Siapa target pasar produk IoT Control Center?",
      "Apa saja masalah yang diatasi produk IoT Control Center?"
    ]
  },
  "/enterprise/product-list-connectivity/team-plan": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa manfaat Teamplan bagi karyawan?",
      "Berapa kuota data terbaik di Teamplan?",
      "Apa harga paket Teamplan?",
      "Bagaimana cara menggunakan Teamplan?"
    ]
  },
  "/enterprise/product-list-connectivity/enterprise-plus": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa nilai tambah pemasangan Enterprise Plus?",
      "Siapa target pasar Enterprise Plus?",
      "Berapa lama masa aktif program enterprise-plus?",
      "Bagaimana cara mengaktifkan Enterprise Plus?"
    ]
  },
  "/enterprise/product-list-connectivity/umkm-plus": {
    "status": true,
    "usecase": null,
    "questions": [
      "Jenis paket apa yang tersedia di UMKM Plus?",
      "Apa saja fitur dari paket UMKM Plus?",
      "Apa manfaat yang diberikan untuk UMKM?"
    ]
  },
  "/enterprise/product-list-connectivity/quota-pooling": {
    "status": true,
    "usecase": null,
    "questions": [
      "Siapa yang bisa jadi Parent dalam Kuota Bersama quota-pooling?",
      "Berapa maksimal anggota di Kuota Bersama quota-pooling?",
      "Bagaimana cara mengelola Kuota Bersama quota-pooling?"
    ]
  },
  "/enterprise/product-list-iot/iot-smart-manufacturing": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-smart-manufacturing#studi-section",
    "questions": [
      "Apa nilai tambah dari produk IoT Smart Manufacturing?",
      "Bagaimana cara kerja sistem pemantauan IoT Smart Manufacturing?",
      "Apakah solusi IoT Smart Manufacturing bisa dikustomisasi?",
      "Apa keunggulan solusi Smart Manufacturing CMMS dibandingkan dengan solusi lainnya?",
      "Bagaimana cara untuk bisa melakukan implementasi solusi IoT Smart Manufacturing pada bisnis?",
      "Apakah ada persyaratan khusus untuk bisa mengadopsi IoT Smart Manufacturing di dalam pabrik?",
      "Bagaimana jika ada kendala di jaringan sistem IoT Smart Manufacturing?",
      "Berapa lama trial untuk IoT Smart Manufacturing?"
    ]
  },
  "/enterprise/product-list-iot/iot-intank": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-intank#studi-section",
    "questions": [
      "Apa fitur utama dari INTANK?",
      "Apa manfaat dari pemantauan tangki INTANK?",
      "Siapa target pasar produk INTANK?",
      "Apa itu Telkomsel Intank Liquid Asset Tank Monitoring?",
      "Apa saja jenis aset cairan di dalam tangki yang dapat dimonitor produk INTANK?"
    ]
  },
  "/enterprise/product-list-iot/iot-asset-performance-management": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-asset-performance-management#studi-section",
    "questions": [
      "Siapa yang bisa menggunakan produkIoT Asset Performance Management?",
      "Apa saja masalah yang ditangani produk?",
      "Apa itu IoT Asset Performance Management?",
      "Bagaimana IoT Asset Performance Management dapat memenuhi kebutuhan pelanggan?",
      "Apa saja fitur dari IoT Asset Performance Management?",
      "Industri apa saja yang dapat diimplementasikan IoT Asset Performance Management?",
      "Apa kelebihan solusi IoT Asset Performance Management dibandingkan solusi lain?"
    ]
  },
  "/enterprise/product-list-iot/iot-envion": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-envion#studi-section",
    "questions": [
      "Apa solusi yang ditawarkan Envion?",
      "Apa manfaat penggunaan produk Envion?",
      "Apa itu Telkomsel Energy Monitoring System?",
      "Pemasangan seperti apa yang akan diimplementasikan pada layanan Energy Monitoring System?",
      "Apakah pemasangan Energy Monitoring System akan merusak perangkat yang sudah dimiliki pelanggan?",
      "Apakah semua jenis perangkat dapat dipasang Energy Monitoring System?"
    ]
  },
  "/enterprise/product-list-connectivity/orbit": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa itu paket Orbit Telkomsel?",
      "Siapa yang cocok dengan paket Orbit?",
      "Apa saja channel pembelian Orbit?",
      "Apa perbedaan antara paket Orbit dan MiFi?",
      "Berapa harga paket data 250GB?"
    ]
  },
  "/enterprise/product-list-iot/fleetsight": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/fleetsight#studi-section",
    "questions": [
      "Apa itu FleetSight?",
      "Bagaimana FleetSight bekerja?",
      "Apa manfaat FleetSight bagi pengguna?",
      "Siapa target pasar FleetSight?",
      "Apa saja proposisi nilai FleetSight?",
      "Apa poin masalah yang diatasi FleetSight?",
      "Bagaimana cara mengintegrasikan FleetSight?",
      "Apa contoh kasus penggunaan FleetSight?",
      "Apa fitur utama dari FleetSight?",
      "Siapa pelanggan sebelumnya FleetSight?"
    ]
  },
  "/enterprise/product-list-iot/fleetsense": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/fleetsense#studi-section",
    "questions": [
      "Apa itu FleetSense?",
      "Bagaimana FleetSense meningkatkan keamanan?",
      "Apa manfaat menggunakan FleetSense?",
      "Apa saja target pasar FleetSense?",
      "Apa poin masalah yang diatasi FleetSense?",
      "Bagaimana cara mengintegrasikan FleetSense?",
      "Apa proposisi nilai FleetSense?",
      "Bagaimana fitur video real-time FleetSense?",
      "Apa contoh kasus penggunaan FleetSense?",
      "Siapa pelanggan sebelumnya FleetSense?"
    ]
  },
  "/enterprise/product-list-cyber/mobile-device-management": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/mobile-device-management#studi-section",
    "questions": [
      "Apakah MDM mendukung Android dan iOS?",
      "Bagaimana cara melacak perangkat yang hilang dengan MDM ?",
      "MDM untuk perangkat yang di-rooted bisa?",
      "Apa keuntungan menggunakan MDM?",
      "Apa saja industri target MDM?"
    ]
  },
  "/enterprise/product-list-cyber/mobile-endpoint-protection": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/mobile-endpoint-protection#studi-section",
    "questions": [
      "MEP dapat digunakan di platform apa saja?",
      "Apa keunggulan MEP dibanding antivirus lain?",
      "Bagaimana cara mengaktifkan MEP secara grup?",
      "Apa saja ancaman yang bisa diatasi MEP?",
      "Dapatkah MEP diintegrasikan dengan MDM?"
    ]
  },
  "/enterprise/product-list-cyber/telkomsel-guard": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/telkomsel-guard#studi-section",
    "questions": [
      "Apa fungsi EPP dalam Telkomsel Guard?",
      "Apakah Telkomsel Guard bisa dipasang offline?",
      "Sistem operasi apa yang kompatibel dengan Telkomsel Guard?",
      "Apa layanan after sales dari Telkomsel Guard?",
      "Bagaimana Telkomsel Guard mendeteksi serangan?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/t-connext": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/t-connext#studi-section",
    "questions": [
      "Apa itu T-Connect?",
      "Bagaimana cara kerja T-Connect?",
      "Siapa target pasar T-Connect?",
      "Apa manfaat T-Connect bagi pengguna?",
      "Jenis kegiatan apa yang ada di T-Connect?",
      "Apa nilai tambah yang diberikan T-Connect?",
      "Bagaimana cara mengakses T-Connect?",
      "Apa yang diharapkan dari kolaborasi di T-Connect?",
      "Apa tujuan dari T-Connect?",
      "Siapa saja pemangku kepentingan yang terlibat dalam T-Connect?"
    ]
  },
  "/enterprise/product-list-cie/cep": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cie/cep#studi-section",
    "questions": [
      "Apa manfaat utama CEP untuk pelanggan?",
      "Bagaimana CEP meningkatkan engagement?",
      "Siapa target pasar dari CEP?",
      "Apa kegunaan analitik dalam CEP?",
      "Bagaimana cara mengintegrasikan CEP?",
      "Apa contoh penggunaan CEP di bisnis?",
      "Apa data yang dikumpulkan CEP?",
      "Bagaimana CEP mengurangi biaya pemasaran?",
      "Apa yang dimaksud dengan profil pelanggan CEP?",
      "Apa tantangan yang dipecahkan CEP?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/cloudx-communication": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/cloudx-communication#studi-section",
    "questions": [
      "Apa itu CloudX?",
      "Siapa target pasar CloudX?",
      "Apa manfaat CloudX bagi perusahaan?",
      "Fitur apa yang ada di CloudX Communication?",
      "Bagaimana cara menggunakan CloudX Meeting?",
      "Apa saja solusi dalam CloudX?",
      "Bagaimana CloudX mengurangi biaya?",
      "Apa yang dimaksud dengan CloudX Hub?",
      "Apa keuntungan menggunakan CloudX?",
      "Bagaimana CloudX mendukung kolaborasi?"
    ]
  },
  "/enterprise/product-list-digital-advertising/myads": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-digital-advertising/myads#studi-section",
    "questions": [
      "Apa itu Telkomsel MyAds?",
      "Bagaimana cara membuat iklan MyAds?",
      "Siapa target pasar MyAds?",
      "Apa manfaat menggunakan MyAds?",
      "Apa itu Location-Based Advertising MyAds?",
      "Bagaimana cara top up saldo MyAds?",
      "Apa batasan karakter untuk SMS MyAds?",
      "Bagaimana cara melacak performa iklan MyAds??",
      "Bisakah saya membatalkan iklan MyAds?",
      "Apa itu Paket Promo di MyAds?"
    ]
  },
  "/enterprise/product-list-digital-advertising/digiads": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-digital-advertising/digiads#studi-section",
    "questions": [
      "Apa itu Telkomsel DigiAds?",
      "Bagaimana cara menggunakan DigiAds?",
      "Apa manfaat DigiAds dari audience targeting?",
      "Apa itu Messaging LBA?",
      "Berapa click-through rate yang diharapkan untuk DigiAds?",
      "Apa saja format iklan yang tersedia di DigiAds?",
      "Bagaimana cara mengetahui hasil kampanye DigiAds?",
      "Apa perbedaan LBA dan Targeted Messaging?",
      "Bisakah kampanye DigiAds dijalankan secara mandiri?",
      "Apa itu post campaign report DigiAds?"
    ]
  },
  "/enterprise/product-list-cie/tSurvey": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cie/tSurvey#studi-section",
    "questions": [
      "Apa itu tSurvey?",
      "Bagaimana cara mendaftar di tSurvey?",
      "Apa saja jenis layanan tSurvey?",
      "Berapa harga paket Basic?",
      "Apa keuntungan bagi responden?",
      "Apa saja jenis pertanyaan yang tersedia?",
      "Bagaimana cara mengupload file?",
      "Apa itu Layanan Managed Service?",
      "Bagaimana cara menghubungi tim CS?",
      "Apa itu fitur Top-Up Survey?"
    ]
  },
  "/enterprise/product-list-iot/iot-managed-cctv": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/iot-managed-cctv#studi-section",
    "questions": [
      "Apa itu IoT Managed CCTV?",
      "Bagaimana cara saya berlangganan IoT Managed CCTV?",
      "Apa saja layanan yang disediakan IoT Managed CCTV?",
      "Berapa jumlah minimal kamera untuk berlangganan IoT Managed CCTV?",
      "Apakah solusi IoT Managed CCTV ini bisa dikustomisasi?"
    ]
  },
  "/enterprise/product-list-connectivity/internet-corporate": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apakah Internet Corporate otomatis diperpanjang setiap bulan?",
      "Apa yang dapat saya lakukan ketika kuota internet-corporate saya habis?",
      "Bagaimana cara saya mengecek sisa kuota paket internet-corporate?",
      "Apakah ada waktu tertentu untuk bisa menggunakan Internet Corporate?",
      "Kemana tagihan paket Internet Corporate akan dikirim?"
    ]
  },
  "/enterprise/product-list-connectivity/device-bundling": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apakah Device Bundling hanya untuk pelanggan corporate?",
      "Jenis perangkat dan layanan apa saja yang termasuk dalam bundling corporate?",
      "Apakah perusahaan bisa melakukan kustomisasi Device Bundling?",
      "Proses seperti apa yang dapat dilakukan perusahaan dalam menentukan bundling yang sesuai untuk kebutuhan mereka?",
      "Apa saja cakupan wilayah pengiriman untuk Device Bundling?"
    ]
  },
  "/enterprise/product-list-connectivity/topup-prepaid": {
    "status": true,
    "usecase": null,
    "questions": [
      "Bagaimana cara untuk berlangganan Top Up Prepaid?",
      "Berapa minimal nomor prepaid yang dapat didaftarkan untuk Top Up Prepaid?",
      "Kemana tagihan layanan Top Up Prepaid dikirimkan?",
      "Apakah harga pulsa Top Up Prepaid sudah termasuk pajak?",
      "Apakah pengisian pulsa Top Up Prepaid dapat menambah masa aktif kartu prabayar?"
    ]
  },
  "/enterprise/product-list-connectivity/roamax": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa itu Paket RoaMAX?",
      "Apakah saya bisa mengaktifkan lebih dari 1 paket RoaMAX?",
      "Apakah kuota Paket RoaMAX dapat dilakukan tethering?",
      "Bagaimana jika masa berlaku kuota Paket RoaMAX telah berakhir?"
    ]
  },
  "/enterprise/product-list-connectivity/bulk-package": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apakah yang dimaksud dengan Paket Bulk Package?",
      "Siapa saja yang dapat menikmati layanan paket Bulk Package?",
      "Bagaimana monitoring pemakaian Bulk Package?",
      "Berapa minimal subscription untuk Bulk Package?",
      "Apakah Bulk Package dapat digunakan untuk eksternal?"
    ]
  },
  "/enterprise/product-list-connectivity/web2sms": {
    "status": true,
    "usecase": null,
    "questions": [
      "Apa yang dimaksud dengan solusi Enterprise SMS Broadcast?",
      "Bagaimana cara pembayaran untuk solusi Web2SMS?",
      "Apakah Web2SMS dapat digunakan untuk eksternal?",
      "Berapa minimum subscription untuk Web2SMS?",
      "Bagaimana monitoring pemakaian Web2SMS?"
    ]
  },
  "/enterprise/product-list-communication-collaboration/cloudx-hub": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-communication-collaboration/cloudx-hub#studi-section",
    "questions": [
      "Apa itu CloudX Hub?",
      "Bagaimana cara kerja CloudX Hub?",
      "Apa saja tipe perangkat yang dapat digunakan untuk mengakses CloudX Hub?",
      "Apakah solusi CloudX Hub dapat digunakan dengan kartu prabayar?",
      "Apakah untuk CloudX Hub saya bisa mengatur suara IVR secara mandiri?"
    ]
  },
  "/enterprise/product-list-cyber/telco-verify": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/telco-verify#studi-section",
    "questions": [
      "Apakah Telco Verify hanya dapat digunakan pada carrier network Telkomsel?",
      "Perangkat apa saja yang dapat menggunakan solusi Telco Verify?",
      "Bagaimana proses autentikasi jika pengguna terhubung melalui WiFi?",
      "Bagaimana proses autentikasi Telco Verify jika pengguna terhubung melalui WiFi?",
      "Apakah proses autentikasi Telco Verify tetap dapat dilakukan jika pengguna tidak memiliki paket data?",
      "Apakah data pengguna Telco Verify terjamin aman dengan solusi ini?"
    ]
  },
  "/enterprise/product-list-cyber/network-security": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/network-security#studi-section",
    "questions": [
      "Apakah Network Security bisa routing ke prime atau cloud?",
      "Apakah Network Security bisa membantu untuk mengurangi penggunaan berlebih perusahaan?",
      "Apakah Network Security bisa self service atau perlu menghubungi Telkomsel?",
      "Apakah jenis perangkatnya tergantung pada tipe tertentu?",
      "Untuk Network Security Apakah jenis perangkatnya tergantung pada tipe tertentu?"
    ]
  },
  "/enterprise/product-list-cyber/managed-detection-and-response": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cyber/managed-detection-and-response#studi-section",
    "questions": [
      "Apa itu Managed Detection & Response?",
      "Kenapa perlu berlangganan Managed Detection & Response?",
      "Apa perbedaan Managed Detection & Response dan SOC biasa?",
      "Apa perbedaan Managed Detection & Response dan layanan siber profesional?",
      "Apakah Managed Detection & Response dapat berkonsultasi dari jarak jauh?"
    ]
  },
  "/enterprise/product-list-iot/fuel-management-system": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/fuel-management-system#studi-section",
    "questions": [
      "Apa saja alat yang dipasang jika menggunakan layanan Fuel Management System (FMS) Telkomsel?",
      "Apa keunggulan Fuel Management System (FMS) Telkomsel?",
      "Seperti apa model bisnis untuk solusi Fuel Management System (FMS) Telkomsel?",
      "Jenis perangkat seperti apa yang dapat dipasang Fuel Management System?",
      "Apakah Fuel Management System Telkomsel memberikan trial?",
      "Apa saja parameter yang bisa diukur menggunakan Fuel Management System Telkomsel?"
    ]
  },
  "/enterprise/product-list-iot/connected-worker": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/connected-worker#studi-section",
    "questions": [
      "Jenis data apa yang dapat ditampilkan di dashboard Connected Worker?",
      "Apakah dashboard Connected Worker dapat dikustomisasi?",
      "Bagaimana dashboard Connected Worker dapat membantu dalam pengambilan keputusan?",
      "Apakah dashboard Connected Worker bisa diintegrasikan dengan software atau sistem lain?",
      "Apakah dashboard Connected Worker dapat diakses di perangkat seluler?"
    ]
  },
  "/enterprise/product-list-iot/nb-iot": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-iot/nb-iot#studi-section",
    "questions": [
      "Seberapa luas cakupan jaringan NB-IoT?",
      "Apa perbedaan antara NB-IoT dengan teknologi LPWAN lainnya?",
      "Apakah saat berlangganan NB-IoT, akan memiliki akses ke layanan IoT Control Center?",
      "Bagaimana cara mendapatkan device kit IoT Control Center?",
      "Bagaimana cara saya mendaftar untuk berlangganan NB-IoT?"
    ]
  },
  "/enterprise/product-list-cie/network-and-iot-analytics": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cie/network-and-iot-analytics#studi-section",
    "questions": [
      "Bagaimana jika saya ingin menggunakan layanan network and iot analytics ?",
      "Bagaimana cara kerja layanan network and iot analytics ?",
      "Bagaimana model penagihan untuk berlangganan Network & IoT Analytics?",
      "Perangkat seperti apa yang bisa dimonitor Network & IoT Analytics?",
      "Apakah Network & IoT Analytics memberikan trial?"
    ]
  },
  "/enterprise/product-list-cie/video-analytic": {
    "status": true,
    "usecase": "https://www.telkomsel.com/enterprise/product-list-cie/video-analytic#studi-section",
    "questions": [
      "Bagaimana video analytic bekerja?",
      "Bagaimana jika saya ingin memakai video analytic tetapi memiliki keterbatasan sumber daya?",
      "Bagaimana jika perusahaan kami sudah memiliki CCTV, tapi ingin berlangganan Video Analytics?",
      "Industri apa yang dapat menggunakan Video Analytics?",
      "Bagaimana jika cakupan sinyal di lokasi terbatas untuk menggunakan video analytic?"
    ]
  },
  "/embed-test": {
    "questions": [
      "Apa biaya langganan Koperasi Digital terjangkau?",
      "Apa contoh kasus penggunaan FleetSense?"
    ],
    "usecase": "https://www.telkomsel.com/enterprise/product-list-advanced-network-services/GPUaaS#studi-section"
  }
}

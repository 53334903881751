import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import UseCaseIcon from '../icons/use-case'
import ConsultIcon from '../icons/consult'
import useAvaConfig from '../hooks/useAvaConfig'
import urls from '../data/url.json'
import { stripTrailingSlash } from '../helper/url'

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const PopupContainer = styled(motion.div)`
  width: 384px;
  position: absolute;
  top: 0;
  right: 2rem;
  cursor: pointer;
`

const PopupContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 420px;
`

const GradientBox = styled.div`
  background: linear-gradient(160deg, #1ea5e3 0%, #c1003a 100%);
  display: flex;
  width: 500px;
  height: 60px;
  border-radius: 9999px;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  gap: 0.2rem;
`

const ButtonContent = styled.div`
  background: linear-gradient(135deg, #e10043 0%, #b7367a 50%, #1ea5e3 100%);
  display: flex;
  padding: 0.625rem 0.625rem 0.625rem 1rem;
  border-radius: 9999px;
  align-items: center;
  cursor: pointer;
`

const IconWrapper = styled.div``

const OptionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.center ? 'center' : 'flex-start')};
  background-color: #001a41;
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 9999px;
  width: 100%;
`

const OptionText = styled.span`
  font-size: 0.875rem;
  margin-left: 0.5rem;
`

const CloseIcon = styled.img`
  cursor: pointer;
`

const Magic = () => {
  const path = window.location.pathname
  const currentData = useMemo(() => {
    const pathItem = stripTrailingSlash(path)
    const data = (urls as any)[pathItem]
    return data
  }, [path])

  const randomQuestion = currentData?.questions?.[0]

  const config = useAvaConfig()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => setIsOpen(prev => !prev), [])

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data === 'close-popup') {
        setIsOpen(false)
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      const windowHeight = window.innerHeight

      // Check if the user has scrolled at least one page (window height)
      if (scrollTop >= windowHeight) {
        setIsOpen(true)
      }
    }

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const buttonVariants = {
    hover: { scale: 1.01 },
    tap: { scale: 1.02 },
  }

  const popupVariants = {
    hidden: { width: 0, opacity: 0 },
    visible: { width: 384, opacity: 1 },
  }

  if (!config) {
    return <></>
  }

  if (!currentData) {
    return <></>
  }

  return (
    <ButtonWrapper>
      <motion.div variants={buttonVariants}>
        <ButtonContent onClick={toggle}>
          <ConsultIcon />
          <UseCaseIcon />
        </ButtonContent>
        <AnimatePresence>
          {isOpen && (
            <PopupContainer
              variants={popupVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
              }}
            >
              <PopupContent>
                <CloseIcon
                  src={`${config.baseUrl}/revamp/chevron.svg`}
                  onClick={() => setIsOpen(false)}
                  alt="Close"
                />
                <GradientBox>
                  <OptionButton
                    center
                    onClick={() => {
                      window.ted.openTed(randomQuestion)
                    }}
                  >
                    <img
                      src={`${config.baseUrl}/revamp/buble.svg`}
                      alt="About"
                    />
                    <OptionText>Summarize</OptionText>
                  </OptionButton>
                  <OptionButton
                    center
                    onClick={() => {
                      window.open(currentData.usecase, '_blank')?.focus()
                    }}
                  >
                    <img
                      src={`${config.baseUrl}/revamp/list.svg`}
                      alt="Use Cases"
                    />
                    <OptionText>See Use Cases</OptionText>
                  </OptionButton>
                </GradientBox>
              </PopupContent>
            </PopupContainer>
          )}
        </AnimatePresence>
      </motion.div>
    </ButtonWrapper>
  )
}

export default Magic

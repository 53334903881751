import React from 'react'

const AskTedIcon = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5133 4.69973L16.1165 0.0252242C16.037 -0.0440914 15.9234 0.0406277 15.9553 0.144828C16.1301 0.716568 16.0225 1.39206 15.6451 1.88542L11.5268 7.25806C10.1173 9.09606 10.3764 11.803 12.105 13.3003L17.5013 17.9748C17.5809 18.0441 17.6945 17.9594 17.6626 17.8552C17.4877 17.2835 17.5954 16.608 17.9727 16.1146L22.0911 10.742C23.501 8.90442 23.2419 6.19703 21.5133 4.69973Z"
        fill="url(#paint0_linear_2057_26717)"
      />
      <path
        d="M21.5133 4.69973L16.1165 0.0252242C16.037 -0.0440914 15.9234 0.0406277 15.9553 0.144828C16.1301 0.716568 16.0225 1.39206 15.6451 1.88542L11.5268 7.25806C10.1173 9.09606 10.3764 11.803 12.105 13.3003L17.5013 17.9748C17.5809 18.0441 17.6945 17.9594 17.6626 17.8552C17.4877 17.2835 17.5954 16.608 17.9727 16.1146L22.0911 10.742C23.501 8.90442 23.2419 6.19703 21.5133 4.69973Z"
        fill="white"
      />
      <path
        d="M0.355649 5.61049C-0.119418 5.46053 -0.118119 4.82687 0.356969 4.67697C1.17802 4.41792 1.98584 4.1643 2.79571 3.91698C3.04931 3.83903 3.1852 3.71413 3.2631 3.44484C3.63528 2.15421 3.3886 3.02053 3.77636 1.73433C3.78066 1.72039 3.89559 1.33777 4.0311 0.888112C4.17611 0.406907 4.77267 0.407081 4.91963 0.887665C5.03602 1.26828 5.14331 1.61973 5.20448 1.82291C5.46241 2.68038 5.45462 2.66798 5.70476 3.52811C5.76015 3.71767 5.85276 3.82131 6.04232 3.87978C7.30166 4.26776 6.97709 4.17298 8.23384 4.56894C8.25104 4.57427 8.37618 4.61363 8.55382 4.66963C9.03317 4.82074 9.03592 5.48809 8.55797 5.64378C8.44705 5.67991 8.36226 5.70734 8.32039 5.7205C7.20126 6.07394 7.14933 6.08014 6.02847 6.42561C5.8545 6.47876 5.76708 6.57709 5.71601 6.74805C5.45209 7.63662 5.18595 8.52443 4.91612 9.42364C4.77241 9.90258 4.18837 9.90542 4.04223 9.42726C3.90256 8.97029 3.78253 8.57592 3.77636 8.55511C3.3886 7.26891 3.63528 8.1122 3.2605 6.82246C3.18953 6.57886 3.06316 6.46104 2.8338 6.39018C1.59349 6.01105 1.93971 6.10849 0.703729 5.7205C0.687169 5.7151 0.549091 5.67155 0.355649 5.61049Z"
        fill="url(#paint1_linear_2057_26717)"
      />
      <path
        d="M0.355649 5.61049C-0.119418 5.46053 -0.118119 4.82687 0.356969 4.67697C1.17802 4.41792 1.98584 4.1643 2.79571 3.91698C3.04931 3.83903 3.1852 3.71413 3.2631 3.44484C3.63528 2.15421 3.3886 3.02053 3.77636 1.73433C3.78066 1.72039 3.89559 1.33777 4.0311 0.888112C4.17611 0.406907 4.77267 0.407081 4.91963 0.887665C5.03602 1.26828 5.14331 1.61973 5.20448 1.82291C5.46241 2.68038 5.45462 2.66798 5.70476 3.52811C5.76015 3.71767 5.85276 3.82131 6.04232 3.87978C7.30166 4.26776 6.97709 4.17298 8.23384 4.56894C8.25104 4.57427 8.37618 4.61363 8.55382 4.66963C9.03317 4.82074 9.03592 5.48809 8.55797 5.64378C8.44705 5.67991 8.36226 5.70734 8.32039 5.7205C7.20126 6.07394 7.14933 6.08014 6.02847 6.42561C5.8545 6.47876 5.76708 6.57709 5.71601 6.74805C5.45209 7.63662 5.18595 8.52443 4.91612 9.42364C4.77241 9.90258 4.18837 9.90542 4.04223 9.42726C3.90256 8.97029 3.78253 8.57592 3.77636 8.55511C3.3886 7.26891 3.63528 8.1122 3.2605 6.82246C3.18953 6.57886 3.06316 6.46104 2.8338 6.39018C1.59349 6.01105 1.93971 6.10849 0.703729 5.7205C0.687169 5.7151 0.549091 5.67155 0.355649 5.61049Z"
        fill="white"
      />
      <path
        d="M3.20873 14.7161C2.89202 14.6161 2.89288 14.1937 3.20961 14.0937C3.75697 13.921 4.29552 13.7519 4.83544 13.5871C5.00451 13.5351 5.0951 13.4518 5.14703 13.2723C5.39515 12.4119 5.2307 12.9894 5.4892 12.132C5.49207 12.1227 5.56869 11.8676 5.65903 11.5678C5.7557 11.247 6.15341 11.2471 6.25138 11.5675C6.32898 11.8213 6.40051 12.0556 6.44128 12.191C6.61324 12.7627 6.60804 12.7544 6.7748 13.3278C6.81173 13.4542 6.87347 13.5233 6.99984 13.5623C7.8394 13.8209 7.62302 13.7577 8.46085 14.0217C8.47232 14.0253 8.55575 14.0515 8.67417 14.0888C8.99374 14.1896 8.99558 14.6345 8.67695 14.7383C8.60299 14.7623 8.54647 14.7806 8.51856 14.7894C7.77247 15.025 7.73785 15.0292 6.99061 15.2595C6.87463 15.2949 6.81635 15.3605 6.7823 15.4744C6.60636 16.0668 6.42893 16.6587 6.24904 17.2582C6.15324 17.5774 5.76388 17.5793 5.66645 17.2606C5.57333 16.9559 5.49332 16.693 5.4892 16.6791C5.2307 15.8217 5.39515 16.3839 5.1453 15.524C5.09798 15.3616 5.01374 15.2831 4.86083 15.2359C4.03396 14.9831 4.26477 15.0481 3.44078 14.7894C3.42974 14.7858 3.33769 14.7568 3.20873 14.7161Z"
        fill="url(#paint2_linear_2057_26717)"
      />
      <path
        d="M3.20873 14.7161C2.89202 14.6161 2.89288 14.1937 3.20961 14.0937C3.75697 13.921 4.29552 13.7519 4.83544 13.5871C5.00451 13.5351 5.0951 13.4518 5.14703 13.2723C5.39515 12.4119 5.2307 12.9894 5.4892 12.132C5.49207 12.1227 5.56869 11.8676 5.65903 11.5678C5.7557 11.247 6.15341 11.2471 6.25138 11.5675C6.32898 11.8213 6.40051 12.0556 6.44128 12.191C6.61324 12.7627 6.60804 12.7544 6.7748 13.3278C6.81173 13.4542 6.87347 13.5233 6.99984 13.5623C7.8394 13.8209 7.62302 13.7577 8.46085 14.0217C8.47232 14.0253 8.55575 14.0515 8.67417 14.0888C8.99374 14.1896 8.99558 14.6345 8.67695 14.7383C8.60299 14.7623 8.54647 14.7806 8.51856 14.7894C7.77247 15.025 7.73785 15.0292 6.99061 15.2595C6.87463 15.2949 6.81635 15.3605 6.7823 15.4744C6.60636 16.0668 6.42893 16.6587 6.24904 17.2582C6.15324 17.5774 5.76388 17.5793 5.66645 17.2606C5.57333 16.9559 5.49332 16.693 5.4892 16.6791C5.2307 15.8217 5.39515 16.3839 5.1453 15.524C5.09798 15.3616 5.01374 15.2831 4.86083 15.2359C4.03396 14.9831 4.26477 15.0481 3.44078 14.7894C3.42974 14.7858 3.33769 14.7568 3.20873 14.7161Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2057_26717"
          x1="-3.05119e-08"
          y1="2.475"
          x2="24.3295"
          y2="11.0998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#599AFF" />
          <stop offset="1" stop-color="#FF5B96" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2057_26717"
          x1="-3.05119e-08"
          y1="2.475"
          x2="24.3295"
          y2="11.0998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#599AFF" />
          <stop offset="1" stop-color="#FF5B96" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2057_26717"
          x1="-3.05119e-08"
          y1="2.475"
          x2="24.3295"
          y2="11.0998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#599AFF" />
          <stop offset="1" stop-color="#FF5B96" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default AskTedIcon

import React from 'react'

import { useEffect, useRef, useState } from 'react'

import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import useAvaConfig from '../hooks/useAvaConfig'
import { LANDING_PAGE } from '../config'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const PopupContainer = styled(motion.div)`
  width: 384px;
  position: absolute;
  top: 3px;
  right: 10px;
  cursor: pointer;
`

const PopupContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`

const GradientBox = styled.div`
  background: linear-gradient(160deg, #1ea5e3 0%, #c1003a 100%);
  display: flex;
  width: 100%;
  height: 66px;
  border-radius: 9999px;
  justify-content: space-between;
  align-items: center;
`

const TedImage = styled(motion.img)`
  margin-top: -1rem;
  padding-right: 0.5rem;
  border-bottom-left-radius: 1.5rem;
  width: 78px;
  height: 80px;
`

const GreetingText = styled(motion.h1)`
  color: white;
  font-size: 14px;
`

const ChatIcon = styled(motion.img)`
  width: 50px;
  height: 50px;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
`

const MagicImg = styled(motion.img)`
  height: ${(props: any) => (props.isOpen ? '80px' : '60px')};
  width: ${(props: any) => (props.isOpen ? '80px' : '60px')};
`

const Ted = () => {
  const pathName = window.location.pathname
  const config = useAvaConfig()

  const iframeRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isIdle, setIsIdle] = useState(false)
  const [isFirstVisit, setIsFirstVisit] = useState(false)
  const [query, setQuery] = useState('')
  const [tedNotfound, setTedNotfound] = useState(false)
  const [idleTime, setIdleTime] = useState(5000)

  const toggle = () => {
    if (!tedNotfound) {
      setQuery('')
    }
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data === 'close-popup') {
        setIsOpen(false)
      } else if (event.data.includes?.('open-ted')) {
        const eventParts = event.data.split('|')
        if (eventParts.length > 1) {
          setQuery(eventParts[1])
        } else {
          setQuery('')
        }

        if (pathName.includes('search')) {
          setTedNotfound(true)
        } else {
          setIsOpen(true)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [config])

  useEffect(() => {
    if (pathName !== LANDING_PAGE) return
    // Check if it's the first visit in this session
    const checkFirstVisit = () => {
      const hasVisitedThisSession = sessionStorage.getItem('hasVisited')
      if (!hasVisitedThisSession) {
        sessionStorage.setItem('hasVisited', 'true')
        // setTimeout(() => {
        setIsFirstVisit(true)
        setIsIdle(true)
        // }, 1000)
      } else {
        setIsFirstVisit(false)
      }
    }

    checkFirstVisit()
  }, [])

  const getIframeStyles = () => {
    const width = window.innerWidth

    let maxHeight = '100%'
    let maxWidth = '100%'
    let widthFrame = '98%'
    let heightFrame = '-webkit-fill-available'
    let right = '0rem'
    let bottom = '2rem'
    let left = ''
    let borderRadius = '0.75rem'

    if (width >= 1660) {
      maxHeight = 'calc(100vh - 12rem)'
      maxWidth = '380px'
    } else if (width >= 1327) {
      maxHeight = 'calc(100vh - 9rem)'
      maxWidth = '380px'
    } else if (width >= 1106) {
      maxHeight = 'calc(100vh - 1rem)'
      maxWidth = '380px'
    } else if (width >= 948) {
      maxHeight = 'calc(100vh - 3rem)'
      maxWidth = '380px'
    } else if (width >= 834) {
      maxHeight = 'calc(100vh - 3rem)'
      maxWidth = '350px'
    } else if (width >= 360) {
      maxHeight = '100vh'
      maxWidth = '100%'
    }

    if (width <= 320) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      left = '0rem'
      borderRadius = '0rem'
      bottom = '0'
    } else if (width <= 375) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      left = '0rem'
      borderRadius = '0rem'
      bottom = '0'
    } else if (width <= 768) {
      widthFrame = '100vw'
      heightFrame = '100vh'
      right = '0rem'
      bottom = '0rem'
      borderRadius = '0rem'
    } else if (width < 1024) {
      widthFrame = '30rem'
      heightFrame = '100vh'
      right = '0rem'
      bottom = '0rem'
      borderRadius = '0rem'
    } else if (width >= 1024) {
      widthFrame = '30rem'
      heightFrame = '50rem'
      right = '1rem'
      bottom = '5rem'
    } else if (width >= 1440) {
      widthFrame = '30rem'
      heightFrame = '50rem'
      right = '1rem'
      bottom = '5rem'
    }

    return {
      border: 'none',
      position: 'fixed',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow:
        'rgba(150, 150, 150, 0.2) 0px 10px 30px 0px, rgba(150, 150, 150, 0.2) 0px 0px 0px 1px',
      bottom,
      right,
      left,
      width: widthFrame,
      maxWidth,
      height: heightFrame,
      maxHeight,
      borderRadius,
      display: 'flex',
      zIndex: 2147483647,
      overflow: 'hidden',
      backgroundColor: '#F3F4F6',
    }
  }

  const buttonVariants = {
    hover: { scale: 1.01 },
    tap: { scale: 1.02 },
  }

  const popupVariants = {
    hidden: { width: 0, opacity: 0 },
    visible: { width: 384, opacity: 1 },
  }

  const contentVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  }

  const greetingMessage = isFirstVisit
    ? "Hi, I'm Ted! Looks like you're new here. Need any assistance?"
    : tedNotfound
    ? "Haven't discovered your desired product yet? im here to help"
    : "Hi, I'm Ted! Have you found what you're looking for?"

  useEffect(() => {
    if (pathName !== LANDING_PAGE) return
    if (isIdle) return
    // if (hasOpen) return

    console.log('idle time', idleTime)
    const timer = setTimeout(() => {
      let newIdleTime = idleTime * 3 > 45000 ? 45000 : idleTime * 3
      setIdleTime(newIdleTime)
      setIsIdle(true)
    }, idleTime)

    return () => {
      clearTimeout(timer)
    }
  }, [pathName, isIdle])

  const showGreet = isIdle || tedNotfound

  if (!config) {
    return <></>
  }

  const imgIcon = isOpen
    ? `${config?.baseUrl}/revamp/close.svg`
    : `${config?.baseUrl}/revamp/ripples-active.png`

  return (
    <>
      <ButtonWrapper>
        <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
          <MagicImg isOpen={!isOpen} src={imgIcon} alt="" onClick={toggle} />

          <AnimatePresence>
            {!isOpen && showGreet && (
              <PopupContainer
                variants={popupVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                transition={{
                  type: 'spring',
                  stiffness: 300,
                  damping: 30,
                }}
              >
                <PopupContent>
                  <img
                    src={`${config.baseUrl}/revamp/x.svg`}
                    onClick={() => {
                      setIsIdle(false)
                      setIsFirstVisit(false)
                      setTedNotfound(false)
                    }}
                  />
                  <GradientBox onClick={toggle}>
                    <TedImage
                      src={`${config.baseUrl}/revamp/Ted half circle.png`}
                      variants={contentVariants}
                      transition={{ delay: 0.1 }}
                    />
                    <GreetingText
                      variants={contentVariants}
                      transition={{ delay: 0.2 }}
                    >
                      {greetingMessage}
                    </GreetingText>
                    <ChatIcon
                      src={`${config.baseUrl}/revamp/chat.svg`}
                      variants={contentVariants}
                      transition={{ delay: 0.3 }}
                      onClick={toggle}
                    />
                  </GradientBox>
                </PopupContent>
              </PopupContainer>
            )}
          </AnimatePresence>
        </motion.div>
      </ButtonWrapper>
      <AnimatePresence>
        {isOpen && (
          <motion.iframe
            ref={iframeRef}
            allow="fullscreen; microphone"
            title="dify chatbot bubble window"
            id="ted-iframe"
            src={`${config.baseUrl}/ted?token=${config.token}&width=${window.innerWidth}&query=${query}`}
            style={getIframeStyles()}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </>
  )
}

export default Ted

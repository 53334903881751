import React from 'react'

const BubbleIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.31746 17.5C2.99353 17.5 2.78689 17.3967 2.69753 17.1902C2.61376 16.989 2.65285 16.7852 2.81481 16.5787C2.92093 16.4428 3.07172 16.2607 3.2672 16.0324C3.46825 15.8095 3.6749 15.5649 3.88713 15.2986C4.09935 15.0322 4.28366 14.774 4.44004 14.524C4.47355 14.4642 4.47913 14.4071 4.45679 14.3528C4.44004 14.293 4.40094 14.2468 4.33951 14.2141C3.44591 13.7467 2.6724 13.1895 2.01896 12.5427C1.37111 11.8904 0.871252 11.1729 0.5194 10.3902C0.173133 9.60743 0 8.78121 0 7.91151C0 6.88417 0.242945 5.92206 0.728836 5.02518C1.22031 4.1283 1.89888 3.34285 2.76455 2.66882C3.6358 1.98937 4.64668 1.45939 5.79718 1.0789C6.94768 0.692966 8.18195 0.5 9.5 0.5C10.818 0.5 12.0523 0.692966 13.2028 1.0789C14.3533 1.45939 15.3614 1.98937 16.2271 2.66882C17.0983 3.34285 17.7769 4.1283 18.2628 5.02518C18.7543 5.92206 19 6.88417 19 7.91151C19 8.72142 18.8464 9.496 18.5392 10.2353C18.2321 10.9745 17.7881 11.654 17.2072 12.2736C16.6264 12.8878 15.9227 13.4233 15.0961 13.8799C14.2751 14.3365 13.348 14.6898 12.3148 14.9398C11.2816 15.1898 10.1618 15.3149 8.95547 15.3149C8.9052 15.3149 8.85215 15.3122 8.7963 15.3067C8.74045 15.3067 8.6846 15.304 8.62875 15.2986C8.5729 15.2986 8.51426 15.3094 8.45282 15.3312C8.39139 15.3529 8.32437 15.3882 8.25176 15.4372C7.89433 15.6872 7.48383 15.9318 7.02028 16.171C6.56232 16.4156 6.09597 16.6384 5.62125 16.8396C5.15212 17.0407 4.7137 17.201 4.306 17.3206C3.8983 17.4402 3.56878 17.5 3.31746 17.5Z"
        fill="white"
      />
    </svg>
  )
}

export default BubbleIcon

import React from 'react'

const ConsultIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: '0 -0.3rem' }}
    >
      <circle cx="30" cy="30" r="30" fill="white" />
      <path
        d="M19.6958 44.1667C19.1558 44.1667 18.8115 43.9945 18.6628 43.6503C18.5197 43.3117 18.5882 42.9731 18.8683 42.6345C19.0348 42.4047 19.2892 42.1012 19.6287 41.7238C19.9738 41.3518 20.3236 40.9415 20.678 40.4927C21.0324 40.0437 21.3395 39.6067 21.5995 39.1817C21.6557 39.0737 21.665 38.9785 21.6282 38.8879C21.5995 38.7883 21.5348 38.7113 21.4344 38.6569C19.9093 37.9112 18.6259 36.9825 17.5844 35.8712C16.5518 34.7507 15.785 33.515 15.2842 32.164C14.7885 30.8123 14.5407 29.3853 14.5407 27.8833C14.5407 26.1407 14.9051 24.4988 15.6339 22.9575C16.3672 21.4138 17.3943 20.0615 18.7153 18.9007C20.043 17.7323 21.5926 16.827 23.364 16.1847C25.1354 15.5383 27.0433 15.2167 29.0879 15.2167C31.1325 15.2167 33.0404 15.5383 34.8118 16.1847C36.5832 16.827 38.1297 17.7323 39.4513 18.9007C40.7785 20.0615 41.8056 21.4138 42.5327 22.9575C43.2677 24.4988 43.6351 26.1407 43.6351 27.8833C43.6351 29.2862 43.3837 30.6378 42.8807 31.9383C42.3777 33.2387 41.6666 34.4392 40.7471 35.5395C39.8275 36.6343 38.7197 37.5892 37.4235 38.404C36.1334 39.2188 34.7167 39.8412 33.1732 40.2712C31.6297 40.7013 29.9743 40.9163 28.2072 40.9163C28.124 40.9163 28.0374 40.9117 27.9473 40.9025C27.8572 40.9025 27.7672 40.8978 27.677 40.8887C27.5869 40.8887 27.4906 40.9071 27.3881 40.944C27.2855 40.981 27.1753 41.041 27.0573 41.1243C26.4946 41.5423 25.8259 41.9512 25.0514 42.3508C24.2831 42.7578 23.5021 43.124 22.7083 43.4493C21.9208 43.7745 21.2061 44.0365 20.5644 44.2352C19.9227 44.4342 19.4177 44.534 19.6958 44.1667Z"
        fill="#B7367A"
      />
    </svg>
  )
}

export default ConsultIcon

'use client'

import React, { useMemo } from 'react'

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Magic from './desktop/Magic'
import Ted from './desktop/Ted'
import TedMobile1 from './mobile/TedMobileV1'
import TedMobile2 from './mobile/TedMobileV2'
import GlobalFont from './globalStyle'
import { LANDING_PAGE } from './config'
import { stripTrailingSlash } from './helper/url'
import urls from '../ted/data/url.json'

const ButtonContainer = styled.div`
  position: absolute;
  right: 1.25rem;
  bottom: 0rem;
  width: 100%;
  z-index: 10000;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10000;
`

const DesktopButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px; // This replaces space-x-4
`

export default function Home() {
  const [isMobile, setIsMobile] = useState(false)
  const pathName = window.location.pathname

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust this breakpoint as needed
    }

    checkMobile() // Initial check
    window.addEventListener('resize', checkMobile)

    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  const path = window.location.pathname
  const currentData = useMemo(() => {
    const pathItem = stripTrailingSlash(path)
    const data = (urls as any)[pathItem]
    return data
  }, [path])

  return (
    <>
      <GlobalFont />
      <Container>
        {isMobile && (
          <>
            {!currentData && <TedMobile1 />}
            {currentData && <TedMobile2 />}

            {/* {pathName !== LANDING_PAGE && <TedMobile2 />} */}
            {/* {pathName === LANDING_PAGE && <TedMobile1 />} */}
          </>
        )}
        <ButtonContainer>
          {isMobile ? null : (
            <DesktopButtons>
              <Magic />
              <Ted />
            </DesktopButtons>
          )}
        </ButtonContainer>
      </Container>
    </>
  )
}
